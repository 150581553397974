import {
  Box,
  Grid,
  Typography,
  Stack,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextareaAutosize,
  Select,
  MenuItem,
  CircularProgress,
  Tabs,
  Tab,
} from '@mui/material';
import React, { Dispatch, FC, useState, useEffect } from 'react';
import { useStyles } from './SummaryStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import { colors } from '../../theme/Theme';
import { BeneficiaryForm } from '../beneficiary/BeneficiaryDetails';
import { SupplierForm } from '../supplier/Supplier';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  dollarFormatter,
  formatPhone,
  GetFileNames,
  GetFilePaths,
  getStatusColor,
  hasAdminPermission,
  hasMemberPermission,
  hasPractitionerPermission,
} from '../../helper/AppHelper';
import { postNewApplication } from '../../service/application/NewApplicationSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LoadingButton } from '@mui/lab';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import {
  selectGetApplicationByIdData,
  selectGetApplicationByIdLoading,
} from '../../service/application/ApplicationListSlice';
import Chip from '../../component/Chip';
import {
  putBatchApproval,
  putStatus,
  selectBatchApprovalLoading,
  selectBatchApprovalStatusData,
  selectStatusData,
  selectStatusLoading,
} from '../../service/application/StatusSlice';
import { Approval, Status, ThreadMsg } from '../../service/application/ApplicationModels';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Toast from '../../component/Toast';
import DocPreview from '../../component/DocPreview';
import { getLink } from '../../service/upload/ViewFileSlice';
import moment from 'moment';
import { NamePathMapper } from '../application/Application';
import { TabPanel } from '../../component/TabPanel';
import { getAttachments, getThread } from '../../service/application/ThreadMsgSlice';
import Thread from '../../component/Thread/ThreadComponent';
import CustomSwitch from '../../component/CustomSwitch';
import Checklist from '../../component/DeliveryChecklist/DeliveryChecklist';
import { getDeliveryChecklist } from '../../service/application/DeliveryChecklist';
import RuleIcon from '@mui/icons-material/Rule';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { gtag } = require('ga-gtag');

interface Props {
  supplierDetails: SupplierForm[];
  beneficiaryDetails: BeneficiaryForm;
  setOpenApplication: Dispatch<any>;
  setModalType: Dispatch<any>;
  setModalOpen: Dispatch<any>;
  getData: () => void;
  fileNamePathMapper: NamePathMapper;
}

const Summary: FC<Props> = ({
  supplierDetails,
  beneficiaryDetails,
  setOpenApplication,
  setModalOpen,
  setModalType,
  getData,
  fileNamePathMapper,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);
  const [openToast, setToastOpen] = useState<boolean>(false);
  const [openApprovalToast, setApprovalToastOpen] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [action, setAction] = useState<string>('');
  const appIds = location.pathname.split('/');
  const appId = appIds[appIds.length - 1];
  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [docLink, setDocLink] = useState<string | undefined>(undefined);
  const [docName, setDocName] = useState<string | undefined>(undefined);
  const [textArea, setTextArea] = useState<string | undefined>(undefined);
  const [thread, setThread] = useState<ThreadMsg[] | undefined>(undefined);
  const [checklist, setChecklist] = useState<ThreadMsg[] | undefined>(undefined);
  const [attachments, setAttachments] = useState(undefined);
  const dispatch = useAppDispatch();

  const fetchThread = async () => {
    const response = await dispatch(getThread(appId));
    if (response.type.includes('fulfilled')) {
      setThread(response.payload.results);
    }
  };

  const fetchChecklist = async () => {
    const response = await dispatch(getDeliveryChecklist(appId));
    if (response.type.includes('fulfilled')) {
      setChecklist(response.payload);
    }
  };

  const fetchThreadAttachments = async () => {
    const response = await dispatch(getAttachments(appId));
    if (response.type.includes('fulfilled')) {
      setAttachments(response.payload);
    }
  };

  useEffect(() => {
    if (!supplierDetails || supplierDetails.length === 0) {
      if (!appId) navigate('/new-application/supplier');
    }
    getData();
    if (hasAdminPermission() || hasMemberPermission()) {
      fetchThread();
      fetchThreadAttachments();
      fetchChecklist();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const styles = useStyles();
  const [mode, setMode] = useState<string>('Review');
  const [activeTab, setActiveTab] = useState(0);

  const appData = useAppSelector(selectGetApplicationByIdData);
  const loading = useAppSelector(selectStatusLoading);
  const approvalLoading = useAppSelector(selectBatchApprovalLoading);
  const statusData = useAppSelector(selectStatusData);
  const approvalStatusData = useAppSelector(selectBatchApprovalStatusData);
  const appGetByIdLoading = useAppSelector(selectGetApplicationByIdLoading);

  useEffect(() => {
    if (appData?.applicationStatus) {
      if (appData.applicationStatus === Status.AwaitingApproval)
        if (hasMemberPermission() || hasAdminPermission()) {
          setActiveTab(1);
        }
    }
  }, [appData]);

  const profile = JSON.parse(localStorage.getItem('profile')!);
  const loggedInUserEmail = profile ? profile.email : '';

  const status =
    mode === 'Saved' ? Status.AwaitingApproval : appData ? appData.applicationStatus : '';

  const handleTabChange = (_: React.SyntheticEvent, tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleTextArea = (e: any) => {
    setTextArea(e.target.value);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const getStatus = (action: string) => {
    switch (action) {
      case 'Reject':
        return Status.Rejected;
      case 'Approve':
        return Status.Approved;
      case 'Delete':
        return Status.Deleted;
    }
  };

  const handlePrimary = async (batchApproval: boolean) => {
    setMode(getStatus(action)!);
    const data = {
      applicationStatus: 'Deleted',
      applicationId: appId,
    };
    gtag('event', 'application status update', {
      appId: `${appId}`,
      action: `${action}`,
      userEmail: `${loggedInUserEmail}`,
    });
    const response =
      action === 'Delete'
        ? await dispatch(postNewApplication(data))
        : batchApproval
        ? await dispatch(putBatchApproval(appId))
        : await dispatch(
            putStatus({
              applicationId: appId,
              approvalStatus: getStatus(action)!,
              message: textArea,
            }),
          );
    if (response.type.includes('fulfilled')) {
      setOpenApplication(true);
      setOpen(false);
      setOpenConfirmation(true);
      setToastOpen(true);
      getData();
      if (getStatus(action) === Status.Approved) {
        fetchChecklist();
      }
    } else {
      setOpenApplication(true);
      setOpen(false);
      setToastOpen(true);
    }
  };

  const handleAction = (action: string) => {
    setOpen(true);
    setAction(action);
    setTextArea(undefined);
  };

  const handlePreview = async (name: string, path: string) => {
    setOpenPreview(true);
    const response = await dispatch(getLink({ path }));
    if (response.type.includes('fulfilled')) {
      setDocName(name);
      setDocLink(response.payload.message);
    }
  };

  const { organisation, department, phoneNo, email, name } = appData?.applicantInfo || {};

  const {
    firstName,
    lastName,
    preferredName,
    dob,
    parentEmail,
    parentFirstName,
    parentLastName,
    file,
    engSpeaking,
    parentPhone,
  } = beneficiaryDetails;

  const getPostData = (isDraft?: boolean) => {
    const data = {
      applicationStatus: isDraft ? Status.Draft : Status.AwaitingApproval,
      applicationId: appId,
    };
    return data;
  };

  const btnForAdmin = (): string => {
    if (
      appData &&
      appData.approvals.length === 0 &&
      (status === Status.AwaitingApproval || status === Status.QuoteNotFinal)
    )
      return 'Approve';
    return 'Send approval requests';
  };

  const isDisabled = (button?: string) => {
    if (button === 'Approve' && status === Status.Approved) {
      return true;
    }
    if (
      btnForAdmin() === 'Send approval requests' &&
      button === 'Approve' &&
      hasAdminPermission()
    ) {
      let flag = false;
      appData.approvals.forEach((a: Approval) => {
        if (a.approvalStatus === Status.RequestNotSent) {
          flag = true;
        }
      });
      return !flag;
    }
    return false;
  };

  const showPrimaryBtn = () => {
    if (hasMemberPermission() && appData) {
      let flag = false;
      let approvalStatus = '';
      appData.approvals.forEach((a: Approval) => {
        if (a.email === loggedInUserEmail) {
          flag = true;
          approvalStatus = a.approvalStatus;
        }
      });
      if (flag && approvalStatus !== Status.Approved) return true;
      return false;
    }
    if (hasAdminPermission() && appData) {
      if (status === Status.AwaitingApproval || status === Status.QuoteNotFinal) return true;
      return false;
    }
    return false;
  };

  const showSecondaryBtn = () => {
    if (hasMemberPermission() && appData) {
      let flag = false;
      let approvalStatus = '';
      appData.approvals.forEach((a: Approval) => {
        if (a.email === loggedInUserEmail) {
          flag = true;
          approvalStatus = a.approvalStatus;
        }
      });
      if (flag && approvalStatus !== Status.Approved) return true;
      return false;
    }
    if (hasAdminPermission()) {
      if (
        status === Status.AwaitingApproval ||
        status === Status.Approved ||
        status === Status.QuoteNotFinal
      )
        return true;
      return false;
    }
  };

  const submit = async (isDraft?: boolean) => {
    setMode('Loading');
    gtag('event', 'new application submit', {
      userEmail: `${loggedInUserEmail}`,
    });
    const response = await dispatch(postNewApplication(getPostData(isDraft)));
    if (response.type.includes('fulfilled')) {
      setMode(isDraft ? 'Draft Saved' : 'Saved');
      setOpenApplication(true);
      window.scrollTo(0, 0);
      getData();
    }
  };

  const renderBox = (
    key: string,
    value: string | string[] | null | undefined,
    paths?: string | string[] | null | undefined,
    list?: boolean,
    showDocIcon?: boolean,
  ) => {
    return (
      <Box py={0.5} px={3}>
        <Stack direction="row">
          <Box minWidth="35%" maxWidth="35%" textAlign="left">
            <Typography
              sx={{ fontSize: 14, textAlign: 'left', color: colors.pageBorder, fontWeight: 600 }}
            >
              {key}
            </Typography>
          </Box>
          {list ? (
            <Box ml={2}>
              {((value as string[]) || []).map((fileName: string, i: number) => (
                <Box display="flex">
                  {showDocIcon && (
                    <DescriptionOutlinedIcon
                      sx={{ padding: 0, mr: 0.5 }}
                      fontSize="small"
                      color="info"
                    />
                  )}
                  <Typography
                    title="View"
                    onClick={() => {
                      if (paths) handlePreview(fileName, paths[i]);
                    }}
                    sx={{
                      textAlign: 'left',
                      wordBreak: 'break-word',
                      color: colors.grey800,
                      fontSize: 15,
                      fontWeight: 400,
                      marginLeft: 0,
                      maxWidth: { xs: 170, sm: 250 },
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflowX: 'hidden',
                      cursor: paths ? 'pointer' : 'text',
                      ':hover': paths
                        ? {
                            textDecoration: 'underline',
                            textDecorationColor: colors.primary,
                            color: colors.primary,
                          }
                        : '',
                    }}
                  >
                    {fileName || 'Uploaded File'}
                  </Typography>
                </Box>
              ))}
            </Box>
          ) : (
            <Box>
              <Typography
                sx={{
                  textAlign: 'left',
                  wordBreak: 'break-word',
                  whiteSpace: 'nowrap',
                  overflowX: 'hidden',
                  color: colors.grey800,
                  fontSize: 15,
                  fontWeight: 400,
                  marginLeft: 2,
                  maxWidth: { xs: 180, sm: 250 },
                  textOverflow: 'ellipsis',
                }}
              >
                {value}
              </Typography>
            </Box>
          )}
        </Stack>
      </Box>
    );
  };

  const navigateTo = (title: string) => {
    setModalOpen(true);
    setModalType(title);
    return;
  };

  const Section = ({ ...props }) => {
    return (
      <Box
        pb={2}
        bgcolor="white"
        sx={{ width: { xs: '100%', sm: '100%', md: '80%', lg: '520px' }, mr: { xs: 0, sm: 2 } }}
        mb={2}
      >
        <Stack
          sx={{
            position: 'relative',
            background: '#F5F5F5',
            border: '1px solid #D6DADE',
            height: 40,
            padding: '8px 16px',
          }}
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Box textAlign="left">
            <Typography sx={{ fontSize: 16 }}>{props.title}</Typography>
          </Box>
          {!props.hideEdit && !props.loading && (
            <Box display="flex" alignItems="center" position="absolute" top={0} right={12}>
              <Button
                onClick={() => navigateTo(props.title)}
                variant="text"
                sx={{
                  fontSize: 14,
                  padding: 0,
                  margin: 0,
                  color: colors.grey600,
                  fontWeight: 400,
                  marginRight: -1,
                }}
              >
                <EditIcon sx={{ marginRight: '4px', width: 14 }} fontSize="small" />
                Edit
              </Button>
            </Box>
          )}
          {props.showLoader && props.loading && <CircularProgress size={20} />}
        </Stack>
        {props.children}
      </Box>
    );
  };

  const showSubmit = () => {
    if (appId && appData && appData.applicationStatus === 'Draft') {
      return true;
    }
    if (!appId) {
      return true;
    }
    if (appData && appData.applicationStatus !== 'Draft' && appData.applicationStatus !== undefined)
      return false;
    return true;
  };

  const check = (status: string) => {
    if (status === 'Off') {
      return false;
    }
    return true;
  };

  const showToggles = (approvalStatus: string) => {
    if (!hasAdminPermission()) {
      return false;
    }
    if (status === Status.Complete || status == Status.Approved) {
      return false;
    }
    if (approvalStatus === Status.Off || approvalStatus === Status.RequestNotSent) {
      return true;
    }
    return false;
  };

  const handleToggle = async (e: any, s: any) => {
    const response = await dispatch(
      putStatus({
        applicationId: appId,
        approvalStatus: e?.target.checked ? Status.RequestNotSent : Status.Off,
        email: s.email,
      }),
    );
    if (response.type.includes('fulfilled')) {
      getData();
    } else if (response.type.includes('failed')) {
    }
  };

  const isItYou = (email: string) => {
    if (email === loggedInUserEmail) return '(You)';
    return '';
  };

  const getPrimaryBtnText = () => {
    if (hasMemberPermission()) return 'Approve';
    if (hasAdminPermission()) return btnForAdmin();
    return 'Approve';
  };

  const getApprovalNames = () => {
    const names: string[] = [];
    appData.approvals.forEach((a: Approval) => {
      if (a.approvalStatus === Status.RequestNotSent) {
        names.push(a.name);
      }
    });
    return names.join(', ');
  };

  return (
    <Box pt={2}>
      <Grid style={{ display: 'flex', flexDirection: 'column' }} container>
        <Box display="flex" alignItems="center" mb={1}>
          <Button
            onClick={() =>
              status === Status.Draft && hasPractitionerPermission()
                ? navigate('/new-application/beneficiary', { state: { applicationId: appId } })
                : navigate('/my-applications')
            }
            className={styles.backBtn}
            variant="text"
          >
            <ArrowBackOutlinedIcon sx={{ mr: 1 }} fontSize="small" />
            {status === Status.Draft && hasPractitionerPermission()
              ? 'Back'
              : 'Back to Applications'}
          </Button>
        </Box>
        {hasPractitionerPermission() ? (
          <Typography
            sx={{
              fontWeight: 500,
              textAlign: 'left',
              mb: 1,
              p: 0.5,
              fontSize: 20,
              color: colors.primary,
            }}
          >
            {mode === 'Saved'
              ? 'Thank you. Your application has been lodged.'
              : mode === 'Review' && status === Status.Draft
              ? 'Review Application'
              : status}
          </Typography>
        ) : (
          <Typography
            sx={{
              fontWeight: 500,
              textAlign: 'left',
              mb: 1,
              p: 0.5,
              fontSize: 20,
              color: colors.primary,
            }}
          >
            {status}
          </Typography>
        )}
        <Stack
          sx={{
            maxWidth: 1110,
            mr: { md: 2, lg: 0 },
            flexDirection: { xs: 'column', sm: 'column', md: 'column', lg: 'row' },
          }}
        >
          <Stack
            bgcolor={'white'}
            py={2}
            mb={1}
            sx={{
              width: { xs: '100%', sm: '100%', md: '80%', lg: '50%', xl: '50%' },
              mr: { xs: 2, sm: 0 },
            }}
          >
            <Stack sx={{ px: 3, height: 25 }} direction="row">
              <Box minWidth="40%" maxWidth="40%" textAlign="left">
                <Typography
                  sx={{ fontSize: 14, textAlign: 'left', color: colors.grey700, fontWeight: 600 }}
                >
                  Lodgement #
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    textAlign: 'left',
                    wordBreak: 'break-word',
                    whiteSpace: 'nowrap',
                    overflowX: 'hidden',
                    color: colors.grey800,
                    fontSize: 15,
                    fontWeight: 400,
                    marginLeft: 2,
                    maxWidth: 250,
                    textOverflow: 'ellipsis',
                  }}
                >
                  {appId}
                </Typography>
              </Box>
            </Stack>
            <Stack sx={{ px: 3, height: 25, my: 0.5 }} direction="row">
              <Box minWidth="40%" maxWidth="40%" textAlign="left">
                <Typography
                  sx={{ fontSize: 14, textAlign: 'left', color: colors.grey700, fontWeight: 600 }}
                >
                  Status
                </Typography>
              </Box>
              <Box ml={2}>
                {((appData && appData.applicationStatus) || mode === 'Saved') && (
                  <Chip
                    color={getStatusColor(status)}
                    showIcon
                    variant={status === Status.RequestNotSent ? 'outlined' : 'contained'}
                    text={status}
                  />
                )}
              </Box>
            </Stack>
            <Stack sx={{ px: 3, height: 25 }} direction="row">
              <Box minWidth="40%" maxWidth="40%" textAlign="left">
                <Typography
                  sx={{ fontSize: 14, textAlign: 'left', color: colors.grey700, fontWeight: 600 }}
                >
                  Date Lodged
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    textAlign: 'left',
                    wordBreak: 'break-word',
                    whiteSpace: 'nowrap',
                    overflowX: 'hidden',
                    color: colors.grey800,
                    fontSize: 15,
                    fontWeight: 400,
                    marginLeft: 2,
                    maxWidth: 250,
                    textOverflow: 'ellipsis',
                  }}
                >
                  {appData?.dateLodged
                    ? moment(appData.dateLodged).format('DD/MM/YYYY')
                    : 'Not Submitted'}
                </Typography>
              </Box>
            </Stack>
          </Stack>
          {(hasPractitionerPermission() || hasAdminPermission()) &&
            status &&
            status !== Status.Complete && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                bgcolor={'white'}
                py={1.5}
                mb={1}
                sx={{
                  width: { xs: '100%', sm: '100%', md: '80%', lg: '45%' },
                }}
              >
                {(mode === 'Review' || mode === 'Loading') && showSubmit() && (
                  <Box
                    sx={{
                      width: { xs: '100%', sm: 180 },
                      float: 'right',
                      mt: 1,
                      mr: { xs: 0, sm: 6 },
                    }}
                    display="flex"
                    flexDirection="column"
                  >
                    <LoadingButton
                      loading={mode === 'Loading'}
                      variant="contained"
                      id="sad"
                      type="submit"
                      sx={{ width: { xs: '100%', sm: 180 } }}
                      className={styles.submitBtn}
                      color="primary"
                      disabled={isDisabled()}
                      onClick={() => submit()}
                      endIcon={<ChevronRightIcon />}
                    >
                      {'Submit'}
                    </LoadingButton>
                  </Box>
                )}
              </Box>
            )}
          {(hasAdminPermission() || (hasMemberPermission() && appData && appData.cost >= 2000)) &&
            (showSecondaryBtn() || showPrimaryBtn()) &&
            status !== Status.Complete && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                bgcolor={'white'}
                py={1.5}
                mb={1}
                mr={6}
                sx={{
                  width: { xs: '100%', sm: '100%', md: '80%', lg: '45%' },
                }}
              >
                {showSecondaryBtn() && (
                  <Box display="flex">
                    <LoadingButton
                      loading={mode === 'Loading'}
                      variant="outlined"
                      fullWidth
                      type="submit"
                      sx={{
                        width: {
                          xs: 100,
                          sm: 120,
                          borderColor: 'lightgrey',
                          borderTopRightRadius: hasAdminPermission() ? 0 : 4,
                          borderBottomRightRadius: hasAdminPermission() ? 0 : 4,
                        },
                      }}
                      className={styles.submitBtn}
                      color="primary"
                      disabled={isDisabled()}
                      onClick={() => handleAction('Reject')}
                    >
                      {'Reject'}
                    </LoadingButton>
                    {hasAdminPermission() && (
                      <Select
                        MenuProps={{
                          classes: { paper: styles.paper, list: styles.list },
                          sx: { mt: { xs: '-2px', sm: 0 } },
                          anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                          },
                        }}
                        inputProps={{
                          classes: {
                            icon: isDisabled() ? styles.iconDisabled : styles.icon,
                          },
                        }}
                        sx={{
                          width: 38,
                          height: 40,
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          ml: '-1px',
                          p: 0,
                        }}
                        disabled={isDisabled()}
                      >
                        <MenuItem
                          sx={{
                            pr: 0,
                            pt: 0,
                            pb: 0,
                            '&:hover': {
                              backgroundColor: '#fff',
                            },
                          }}
                        >
                          <LoadingButton
                            loading={mode === 'Loading'}
                            variant="text"
                            fullWidth
                            type="submit"
                            sx={{
                              width: { xs: 140, sm: 156 },
                              border: '1px solid lightgrey',
                              pr: { xs: 6, sm: 6 },
                              borderBottom: 'none',
                              borderBottomLeftRadius: 0,
                              borderBottomRightRadius: 0,
                              borderTopRightRadius: 0,
                            }}
                            className={styles.submitBtn}
                            color="primary"
                            disabled={isDisabled()}
                            onClick={() => handleAction('Reject')}
                          >
                            {'Reject'}
                          </LoadingButton>
                        </MenuItem>
                        <MenuItem
                          sx={{
                            pr: 0,
                            pt: 0,
                            '&:hover': {
                              backgroundColor: '#fff',
                            },
                          }}
                        >
                          <LoadingButton
                            loading={mode === 'Loading'}
                            variant="text"
                            type="submit"
                            fullWidth
                            sx={{
                              width: { xs: 140, sm: 156 },
                              pr: 6,
                              border: '1px solid lightgrey',
                              borderTopLeftRadius: 0,
                              borderTopRightRadius: 0,
                            }}
                            className={styles.submitBtn}
                            color="primary"
                            disabled={isDisabled()}
                            onClick={() => handleAction('Delete')}
                          >
                            {'Delete'}
                          </LoadingButton>
                        </MenuItem>
                      </Select>
                    )}
                  </Box>
                )}
                {showPrimaryBtn() ? (
                  <LoadingButton
                    loading={mode === 'Loading'}
                    variant="contained"
                    type="submit"
                    sx={{ minWidth: { xs: 100, sm: 150 }, mr: 2, ml: 2, lineHeight: 1 }}
                    className={styles.submitBtn}
                    color="primary"
                    disabled={isDisabled('Approve')}
                    onClick={() => handleAction(getPrimaryBtnText())}
                  >
                    {getPrimaryBtnText()}
                  </LoadingButton>
                ) : (
                  <Box mx={1} />
                )}
              </Box>
            )}
        </Stack>
        <Grid>
          <Box sx={{ borderTopLeftRadius: 5, borderTopRightRadius: 5, paddingBottom: 2 }}>
            <Box>
              <Box width="100%">
                <Tabs sx={{ height: 60 }} value={activeTab} onChange={handleTabChange}>
                  <Tab
                    icon={<InfoOutlinedIcon fontSize="small" />}
                    iconPosition="start"
                    sx={{ fontFamily: 'Roboto', fontWeight: 500 }}
                    label={'Details'}
                  />
                  {(hasMemberPermission() || hasAdminPermission()) && (
                    <Tab
                      icon={<ModeCommentOutlinedIcon fontSize="small" />}
                      iconPosition="start"
                      sx={{ fontFamily: 'Roboto', fontWeight: 500 }}
                      label="Thread"
                    />
                  )}
                  {(hasMemberPermission() || hasAdminPermission()) &&
                    (status === Status.Approved || status === Status.Complete) && (
                      <Tab
                        icon={<RuleIcon fontSize="small" />}
                        iconPosition="start"
                        sx={{ fontFamily: 'Roboto', fontWeight: 500 }}
                        label="Delivery"
                      />
                    )}
                </Tabs>
              </Box>
              <TabPanel value={activeTab} index={0}>
                <Box display="flex" flexWrap={'wrap'}>
                  <Section
                    showLoader
                    loading={loading === 'loading' || appGetByIdLoading === 'loading'}
                    title="Applicant"
                    hideEdit
                  >
                    <Box mt={2}>
                      {renderBox('Name', name?.fullName)}
                      {renderBox('Organisation', organisation)}
                      {renderBox('Department', department)}
                      {renderBox('Email', email)}
                      {renderBox('Phone', phoneNo)}
                    </Box>
                  </Section>
                  {mode === 'Review' && <Box height={32} bgcolor={colors.background}></Box>}
                  {(hasAdminPermission() || hasMemberPermission()) && (
                    <Section
                      hideEdit
                      showLoader
                      loading={loading === 'loading' || appGetByIdLoading === 'loading'}
                      title={`Approvals (${appData?.receivedApprovals.toString() || ' '}/${
                        appData?.requiredApprovals.toString() || ' '
                      }) -  ${
                        appData?.requiredApprovals.toString() || ''
                      } board member's approval required`}
                    >
                      <Box pt={2}>
                        {(appData && appData.approvals ? appData.approvals : []).map(
                          (s: Approval) => (
                            <Stack sx={{ px: 3, height: 25, my: 0.5 }} direction="row">
                              <Box
                                sx={{
                                  minWidth: { xs: '40%', sm: '45%', md: '35%' },
                                  maxWidth: { xs: '60%', sm: '50%', md: '40%' },
                                }}
                                textAlign="left"
                              >
                                <Typography
                                  sx={{
                                    fontSize: 14,
                                    textAlign: 'left',
                                    color: '#007DDC',
                                    fontWeight: 600,
                                  }}
                                >
                                  {s.name} {isItYou(s.email)}
                                </Typography>
                              </Box>
                              <Stack spacing={3} direction="row">
                                {showToggles(s.approvalStatus) ? (
                                  <CustomSwitch
                                    onChange={(e) => handleToggle(e, s)}
                                    checked={check(s.approvalStatus)}
                                  />
                                ) : (
                                  <Box width={32} />
                                )}
                                <Chip
                                  color={getStatusColor(s.approvalStatus)}
                                  variant={
                                    s.approvalStatus === Status.RequestNotSent
                                      ? 'outlined'
                                      : 'contained'
                                  }
                                  text={s.approvalStatus}
                                />
                              </Stack>
                            </Stack>
                          ),
                        )}
                      </Box>
                    </Section>
                  )}
                  {mode === 'Review' && <Box height={32} bgcolor={colors.background}></Box>}
                  <Section
                    hideEdit={
                      (hasPractitionerPermission() && status !== Status.Draft) ||
                      hasMemberPermission() ||
                      status === Status.Complete
                    }
                    showLoader
                    loading={appGetByIdLoading === 'loading'}
                    title="Supplier"
                  >
                    {/* <Box mt={2}> */}
                    {(supplierDetails || []).map((s: SupplierForm, i: number) => (
                      <Box mt={2} key={`${s.supplier}-${s.item}`}>
                        {renderBox('Name', s.supplier)}
                        {renderBox('Item', s.item, undefined, true)}
                        {s.costType === 'Estimate'
                          ? renderBox(
                              'Estimated cost',
                              `${dollarFormatter(s.lowest)}-${dollarFormatter(s.highest)}`,
                            )
                          : renderBox('Cost', `${dollarFormatter(s.cost)}`)}
                        {renderBox('Quote final', s.final)}
                        {renderBox(
                          'Documents',
                          GetFileNames(s.file),
                          GetFilePaths(s.file, fileNamePathMapper),
                          true,
                          true,
                        )}
                        {i + 1 !== supplierDetails.length && <Divider style={{ margin: 10 }} />}
                      </Box>
                    ))}
                  </Section>
                  {mode === 'Review' && <Box height={32} bgcolor={colors.background}></Box>}
                  <Section
                    hideEdit={
                      (hasPractitionerPermission() && status !== 'Draft') ||
                      hasMemberPermission() ||
                      status === Status.Complete
                    }
                    showLoader
                    loading={appGetByIdLoading === 'loading'}
                    title="Beneficiary"
                  >
                    <Box mt={2}>
                      {renderBox('Name', `${firstName || ''} ${lastName || ''}`)}
                      {renderBox('Preferred name', preferredName)}
                      {renderBox('DOB', dob)}
                      {renderBox(
                        'Parent or carers name',
                        `${parentFirstName || ''} ${parentLastName || ''}`,
                      )}
                      {renderBox('Email', parentEmail)}
                      {renderBox('Phone', formatPhone(parentPhone!))}
                      {renderBox('English speaking', engSpeaking)}
                      {renderBox(
                        'Documents',
                        GetFileNames(file),
                        GetFilePaths(file, fileNamePathMapper),
                        true,
                        true,
                      )}
                    </Box>
                  </Section>
                </Box>
              </TabPanel>
              <TabPanel value={activeTab} index={1}>
                <Thread
                  attachments={attachments}
                  fetchThread={fetchThread}
                  threads={thread || []}
                  applicationId={appId}
                  fetchThreadAttachments={fetchThreadAttachments}
                />
              </TabPanel>
              <TabPanel value={activeTab} index={2}>
                <Checklist
                  checklist={checklist}
                  fetchChecklist={fetchChecklist}
                  applicationId={appId}
                  getData={getData}
                  status={status}
                />
              </TabPanel>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <Box p={3}>
          <DialogTitle>
            <Box fontWeight="bold" fontSize={18}>
              {action === 'Send approval requests' ? `${action} for` : action} application?
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You are about to{' '}
              {action === 'Send approval requests'
                ? `${action.toLowerCase()} for`
                : action.toLowerCase()}{' '}
              application {appId}
              {action === 'Send approval requests' && ` to ${getApprovalNames()}`}
              {action === 'Reject' &&
                hasAdminPermission() &&
                `. The rejection notice and reason will be sent to the applicant via email.`}
              <br />
              {action === 'Reject' ? (
                <TextareaAutosize
                  value={textArea}
                  onChange={handleTextArea}
                  placeholder="Please explain the reason for rejection here (optional) "
                  style={{
                    marginTop: '12px',
                    width: '100%',
                    height: 100,
                    fontFamily: 'Arial',
                    padding: 8,
                  }}
                />
              ) : hasMemberPermission() ? (
                'This is cannot be undone.'
              ) : (
                ''
              )}
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ pr: 3 }}>
            <Button sx={{ width: 120 }} variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              loading={loading === 'loading'}
              sx={{ minWidth: 120 }}
              variant="contained"
              onClick={() =>
                handlePrimary(hasAdminPermission() && action === 'Send approval requests')
              }
              autoFocus
            >
              {action}
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog open={openConfirmation} onClose={handleCloseConfirmation}>
        <Box p={3}>
          <DialogTitle>
            <Box display="flex" flexDirection="column" fontWeight="bold" fontSize={18}>
              {action === 'Approve' || action === 'Send approval requests' ? (
                <CheckCircleOutlineIcon sx={{ fontSize: 54, mb: 3, ml: -1 }} color="success" />
              ) : (
                <CancelOutlinedIcon sx={{ fontSize: 54, mb: 3, ml: -1 }} color="error" />
              )}
              {action === 'Send approval requests'
                ? 'Approval Requests Sent'
                : `Application ${action === 'Reject' ? 'Rejected' : `${action}d`}`}
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You have successfully{' '}
              {action === 'Send approval requests'
                ? 'sent approval requests for'
                : `${action === 'Reject' ? 'rejected' : `${action.toLowerCase()}d`}`}{' '}
              application {appId}.
              <br />
              {action === 'Approve' && appData.cost >= 2000 && appData.cost <= 10000 && (
                <div>This application requires 1 additional approval from board members.</div>
              )}
              {action === 'Approve' && appData.cost > 10000 && (
                <div>This application requires 3 additional approval from board members.</div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              loading={loading === 'loading'}
              sx={{ width: 120 }}
              variant="contained"
              onClick={handleCloseConfirmation}
              autoFocus
            >
              CLOSE
            </LoadingButton>
          </DialogActions>
        </Box>
      </Dialog>
      <Toast
        loading={loading}
        response={statusData}
        open={openToast}
        setOpen={setToastOpen}
        errorMsg="Something went wrong, please try again later."
        autoHideDuration={3000}
      />
      <Toast
        loading={approvalLoading}
        response={approvalStatusData}
        open={openApprovalToast}
        setOpen={setApprovalToastOpen}
        errorMsg="Something went wrong, please try again later."
        autoHideDuration={3000}
      />
      <DocPreview
        open={openPreview}
        setOpen={setOpenPreview}
        link={docLink}
        name={docName || 'Uploaded File'}
        setDocLink={setDocLink}
        setDocName={setDocName}
      />
    </Box>
  );
};

export default Summary;
